import {
    assocIn,
    getIn,
    dissoc
} from './functional';


const k = '6p';

const getUserState = function ()
{
    if ( typeof window === 'undefined' ) return {};

    return JSON.parse(
        window.localStorage.getItem(k) || "{}"
    );
};

const getInUserState = function (keyPath)
{
    if ( typeof window === 'undefined' ) return {};

    return getIn( getUserState(), keyPath );
};


const setUserState = function (state)
{
    if ( typeof window === 'undefined' ) return {};

    return window.localStorage.setItem(k, JSON.stringify(state));
};

const assocInUserState = function (keyPath, value)
{
    if ( typeof window === 'undefined' ) return {};

    return setUserState( assocIn( getUserState(), keyPath, value ));
};

const dissocInUserState = function (keyPath)
{
    if ( typeof window === 'undefined' ) return {};

    const s = getUserState();
    const n = getIn( s, keyPath.slice(0, -1) );
    const m = dissoc( n, keyPath.slice(-1)[0] );
    return assocInUserState( keyPath.slice(0, -1), m );
};

export {
    getUserState,
    getInUserState,
    setUserState,
    assocInUserState,
    dissocInUserState
};
