import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { graphql, Link } from 'gatsby';

import Layout from '../../components/layout';
import { newEvent, trackCustomEvent } from '../../utils/event';

import { assocInUserState } from '../../utils/user_state';
import { redirect } from '../../utils/redirect';
import { remote } from '../../utils/http/request';
import { merge, updateIn } from '../../utils/functional';
import { refreshSessionId } from '../../utils/session_id';
import { fieldState, fieldsState, inputDisabledState, messageState, messagesState  } from '../../utils/state';
import { passwordIsValid } from '../../utils/validate';

import { labelNameTag, inputTag } from '../../components/login/input';
import { Message, messageByFieldError } from '../../components/message';
import { colors } from '../../components/colors';
import * as styles from "../../components/login/login.module.css";
import * as linkStyles from '../../components/link.module.css';
import * as buttonStyles from "../../components/button.module.css";

import { RenderHead } from "../../components/head";
const title = 'Reset Password';
export const Head = () => RenderHead({ title });


const redirectDestination = '/login';


//
// messages

const errorMessageExpired = function ()
{
    return (
        <div>
          <span>Your reset password link has expired.</span>

          <Link
            className={ linkStyles.errorLink }
            to={ '/password/forgot' } >
            Reset again
          </Link>
        </div>
    );
}();

const messageGlobalLocal = merge(
    messageByFieldError.global,
    {
        expired: errorMessageExpired,
        invalid: errorMessageExpired,
        notfound: errorMessageExpired
    });

const messageByFieldErrorLocal = updateIn(messageByFieldError, ['global'], (m) => merge(m, messageGlobalLocal));

const messageBase =  {
    global:          {},
    password:        { base: messageByFieldErrorLocal.password.invalid },
    passwordConfirm: {}
};



//
// children

const Field = ({ fieldName, label }) => {
    const [field, setField] = useRecoilState(fieldState(fieldName));
    const setMessage = useSetRecoilState(messageState(fieldName));
    const onChange = ({ target }) => {
        const v = target.value;
        setField(v);
        setMessage(messageBase[fieldName]);
    };
    
    return (
        <div
          style={{
              width: `100%`,
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `flex-start`,
              alignItems: `flex-start`
          }} >
          
          <label
            style={{
                width: `inherit`,
                margin: `50px 0 0`,
            }} >

            { labelNameTag(label) }
            { inputTag( "password", fieldName, field, onChange ) }

          </label>

          <Message
            stateKey={ fieldName }
            name={ 'Message_' + fieldName }
            style={{
                margin: `5px 0 0`,
                minHeight: `25px`,
                fontSize: `12px`,
                fontStyle: `italic`,
                letterSpacing: `0.12px`
            }}
          />
        </div>
    );
};



//
// helpers

const queryToken = () => {
    if ( typeof window === 'undefined' ) return '';

    return window.location.search
        .replace(/^\?/, '')
        .split('&')
        .map(function (p) { return p.split('='); })
        .filter(function (pv) { return pv[0] === 'token'; })
        .shift()
    [1];
};

const submit = async ({ fields }) => {
    const query = {
        password: fields.passwordConfirm,
        token:    queryToken()
    };
    const result = await remote.userMutation({ query });
    if ( result.error ) {
        const errors = result.error.data;
        if ( errors && Array.isArray(errors)
             && errors[0] && errors[0].message )
            return { global: errors[0].message };
        return { global: result.error.type || 'server' };
    }
    if ( ! result.email ) return { global: 'server' };

    // success
    newEvent({
        'level':           'info',
        'event.namespace': 'web.password.reset',
        'event.name':      'success',
        'email':           result.email
    });
    
    trackCustomEvent({
        category: 'reset-password',
        action: 'success'
    });

    assocInUserState(['messages', 'login', 'passwordChanged'], true );
    redirect(redirectDestination, { delayMillis: 1000 });
    return {};
};




//
// Form

const fieldKs = ['password', 'passwordConfirm'];
const messageKs = ['global'].concat(fieldKs);

const Form = () => {
    const [inFlight, setInFlight] = useRecoilState(inputDisabledState);
    const fields = useRecoilValue(fieldsState(fieldKs));
    const [messages, setMessages] = useRecoilState(messagesState(messageKs));
    const clearMessages = () => setMessages(messageKs.map((k) => messageBase[k]));
    const updateMessage = (k, mm) => setMessages(messageKs.map((mk) =>  mk === k ? merge(messages[mk], mm) : messages[mk]));

    useEffect(clearMessages, [setMessages]);
    
    const handleSubmit = (event) => {
        event.preventDefault();
        if ( inFlight ) return null;
        clearMessages();

        // validations
        if ( ! passwordIsValid(fields.password) )
            return updateMessage( 'password', { error: messageByFieldErrorLocal.password.invalid } );
        if ( fields.password !== fields.passwordConfirm )
            return updateMessage( 'passwordConfirm', { error: messageByFieldErrorLocal.password.match } );
        
        // request
        setInFlight(true);
        return submit({ fields })
            .then((data) => {
                if ( data.global ) {
                    const nm = messageByFieldErrorLocal.global[data.global];
                    updateMessage( 'global', { error: nm });
                }
                return;
            })
            .finally(() => setInFlight(false));
    };
    
    return (
        <form
          onSubmit={ handleSubmit } >

          <div
            className={ styles.container }
            style={{
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `flex-start`
            }} >

            <Field
              fieldName={ 'password' }
              label=    { 'New password' }
            />
            
            <Field
              fieldName={ 'passwordConfirm' }
              label=    { 'Confirm new password' }
            />
            
          </div>

          <Message
            stateKey={ 'global' }
            className={ styles.container }
            name={ 'GlobalMessage' }
            style={{
                margin: `30px 0 0`,
                minHeight: `48px`,
                textAlign: `center`,
                fontSize: `13px`,
                fontStyle: `italic`,
                letterSpacing: `0.13px`
            }} />
          
          <div
            style={{
                marginTop: `15px`,
                width: `100%`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `center`,
                alignItems: `center`
            }} >
            
            <input
              type='submit'
              aria-label='Update password'
              value='Update password'
              disabled={ inFlight }
              className={ buttonStyles.submit }
              style={{
                  width: `203px`,
                  height: `48px`
              }} />
            
          </div>
          
        </form>
    );
};



//
// Component

const Component = ({ data, location }) => {

    useEffect(() => { refreshSessionId(); }, []);
    
    return (
        <Layout
          location={ location }
          removeHorizontalPadding={ true } >                      

          <div
            style={{
                margin: `70px auto 90px`,
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `flex-start`,
                alignItems: `center`
            }} >

            <div
              style={{
                  margin: `0 60px`,
                  fontFamily: `"Overpass", sans-serif`,
                  fontSize: `40px`,
                  fontWeight: `bold`,
                  lineHeight: `1.13`,
                  textAlign: `center`,
                  color: colors.green
              }} >
              Create a new password
            </div>

            <Form />
            
            <div
              style={{
                  margin: `10px 0 40px`,
                  fontSize: `14px`,
                  lineHeight: `1.79`,
                  letterSpacing: `0.14px`,
                  color: colors.black
              }} >

              <Link
                className={ linkStyles.greenLink }
                style={{ margin: `0 3px 0` }}
                to={`/login`} >
                Back to Log In
              </Link>

            </div>
            
          </div>
        </Layout>
    );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`;

export default Component;
