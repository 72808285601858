import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { assoc, reduce, reduceKv, threadLast, zipmap } from './functional';


//
// field

export const fieldState = atomFamily({
    key: 'fieldState',
    default: id => ''
});

export const fieldsState = selectorFamily({
    key: 'fieldsState',
    get: (ids) => ({get}) => {
        if ( ! Array.isArray(ids) ) return get(fieldState(ids));
        return reduce((a,id) => assoc(a,id, get(fieldState(id))), {}, ids);
    },
    set: (ids) => ({set}, newValues) => {
        if ( ! Array.isArray(ids) )
            set(fieldState(ids), newValues);
        else
            threadLast(
                newValues,
                [zipmap, ids],
                [reduceKv, (_,k,v) => set(fieldState(k), v), null] );
    }
});



//
// form

export const formState = atomFamily({
    key: 'formState',
    default: id => null
});

export const formsState = selectorFamily({
    key: 'formsState',
    get: (ids) => ({get}) => {
        if ( ! Array.isArray(ids) ) return get(formState(ids));
        return reduce((a,id) => assoc(a,id, get(formState(id))), {}, ids);
    },
    set: (ids) => ({set}, newValues) => {
        if ( ! Array.isArray(ids) )
            set(formState(ids), newValues);
        else
            threadLast(
                newValues,
                [zipmap, ids],
                [reduceKv, (_,k,v) => set(formState(k), v), null] );
    }
});

export const inputDisabledState = selector({
    key: 'inputDisabledState',
    get: ({get}) => get(formState('inFlight')),
    set: ({set}, newValue) => set(formState('inFlight'), newValue)
});



//
// message

export const messageState = atomFamily({
    key: 'messageState',
    default: id => {}
});

export const messagesState = selectorFamily({
    key: 'messagesState',
    get: (ids) => ({get}) => {
        if ( ! Array.isArray(ids) ) return get(messageState(ids));
        return reduce((a,id) => assoc(a,id, get(messageState(id))), {}, ids);
    },
    set: (ids) => ({set}, newValues) => {
        if ( ! Array.isArray(ids) )
            set(messageState(ids), newValues);
        else
            threadLast(
                newValues,
                [zipmap, ids],
                [reduceKv, (_,k,v) => set(messageState(k), v), null] );
    }
});




//
// remote data

export const remoteDatomState = atomFamily({
    key: 'remoteDatomState',
    default: id => {}
});

export const remoteDataState = selectorFamily({
    key: 'remoteDataState',
    get: (ids) => ({get}) => {
        if ( ! Array.isArray(ids) ) return get(remoteDatomState(ids));
        return reduce((a,id) => assoc(a,id, get(remoteDatomState(id))), {}, ids);
    },
    set: (ids) => ({set}, newValues) => {
        if ( ! Array.isArray(ids) )
            set(remoteDatomState(ids), newValues);
        else
            threadLast(
                newValues,
                [zipmap, ids],
                [reduceKv, (_,k,v) => set(remoteDatomState(k), v), null] );
    }
});



//
// subscription type

export const subscriptionTypeState = atom({
    key: 'subscriptionTypeState',
    default: 'ALL'
});
