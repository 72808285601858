import React from 'react';
import { colors } from '../colors';

function labelNameTag (s)
{
    return (
        <div
          style={{
              fontSize: `13px`,
              lineHeight: `1.15`,
              letterSpacing: `0.13px`,
              color: colors.mediumDarkGrey
          }} >
          { s } 
        </div>
    );
}

function inputTag (type, name, value, onChangeFn)
{
    return (
        <input
          name={ name }
          aria-label={ name }
          type={ type }
          value={ value }
          onChange={ onChangeFn }
          style={{
              width: `100%`,
              outline: `none`,
              borderTop: `none`,
              borderRight: `none`,
              borderBottom: `1px solid ${colors.green}`,
              borderLeft: `none`,
              borderRadius: 0,
              cornerRadius: 0,
              padding: `1px 1px 1px 0px`,  // magic incantation to keep text from jumping on IE
              margin: 0,
              fontSize: `16px`,
              lineHeight: `2.07`,
              letterSpacing: `0.15px`,
              color: colors.green,
          }} />
    );
}


export { labelNameTag, inputTag };
