import { Link } from 'gatsby';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { getIn, merge } from '../utils/functional';
import { messageState } from '../utils/state';
import { colors } from './colors';
import * as linkStyles from './link.module.css';


//
// static message copy

export const messageStateInitial = {
    email:            {},
    global:           {},
    nameFirst:        {},
    nameLast:         {},
    password:         { base: 'Must be at least 8 characters, with 1 letter and 1 number' },
    passwordConfirm:  {},
    passwordCurrent:  {},
    passwordNew:      { base: 'Must be at least 8 characters, with 1 letter and 1 number' },
    promoCode:        { base: 'Optional' },
    country:          {}
};


export const messageByFieldError = {
    country: {
        empty: 'Please select your country of residence',
        invalid: 'Memberships are for purchase only by users residing in the United States, Canada, or Australia'
    },
    email:   {
        connectivity: 'No internet connectivity. Please connect and try again',
        exists:  'There is already an account associated with that email address',
        invalid: 'Please enter a valid email address'
    },
    global: {
        browser: <span>
                   <span>
                     The browser you are using is not supported. Please try a modern browser such as Microsoft Edge, Google Chrome, Mozilla Firefox, or Apple Safari. Contact us at 
                   </span>
                   <a
                     className={ linkStyles.errorLink }
                     href='mailto:help@6pages.com' >
                     help@6pages.com
                   </a>
                   <span> if you need assistance.</span>
                 </span>,
        connectivity: 'No internet connectivity. Please connect and try again',
        field: 'Please address any errors above and try again',
        server: <span>
              <span>Server error. Please try again later or contact us at</span>
              <a
                className={ linkStyles.errorLink }
                href='mailto:help@6pages.com' >
                help@6pages.com
              </a>
              <span>for assistance</span>
            </span>
    },
    nameFirst: { empty: 'Required' },
    nameLast:  { empty: 'Required' },
    password:  {
        incorrect: <span>Current password was incorrect. <Link className={linkStyles.errorLink} to={`/password/forgot`}>Forgot?</Link></span>,
        invalid:  'Must be at least 8 characters, with 1 letter and 1 number',
        match: 'Passwords must match'
    },
    promoCode: {
        invalid: 'Please apply or clear the promo code',
        invalidData: 'Not a valid promo code'
    }
};



//
// Message Component

const messageType = (data) => {
    // const type = ['error', 'info', 'base']
    //       .find(function (k) {
    //           return data && data[k] && data[k].length > 0;
    //       });
    if ( data === null || data === undefined ) return null;
    if (data.error) return 'error';
    if (data.info)  return 'info';
    if (data.base)  return 'base';
    return null;
};

const messageColorByType = {
    error: colors.red,
    info:  colors.orange,
    base:  colors.visibleGrey
};

const messageColor = (type) => {
    const c = messageColorByType[type];
    if (c) return c;
    return messageColorByType.base;
};

const messageContent = (data, type) => {
    const c = getIn(data, [type]);
    if (c) return c;
    return '';
};

export const messageViewData = (data) => {
    const t = messageType(data);
    return {
        type:    t,
        color:   messageColor(t),
        content: messageContent(data, t)
    };
};

export const Message = ({ className, name, stateKey, style }) => {
    const data = useRecoilValue(messageState(stateKey));
    const d = messageViewData(data);
    return (
        <div
          name={ name || 'Message' }
          className={ className }
          style={ merge({color: d.color}, style) } >
          { d.content }
        </div>
    );
};
